<script>
import FlagButton from "@/components/FlagButton.vue";
import VueHtml2pdf from 'vue-html2pdf'
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import {pdfHelper} from "../../../helpers/pdf-helper";

export default {
  components: {
    FlagButton,
    VueHtml2pdf,
    VuePdfEmbed,
  },

  data() {
    return {
      refs: {}
    }
  },

  methods: {
    getContentType() {
      return this.$store.getters['custompath/getCustomPath'].contentType
    },

    getContent() {
      return this.$store.getters['custompath/getCustomPath'].content
    },

    getPdfUrl() {
      return pdfHelper.correctUrl(this.$store.getters['custompath/getCustomPath'].pdfUrl)
    },

    setRef(refName) {
      return (ref) => {
        if (!ref) {
          return
        }

        this.$set(this.refs, refName, ref);
        this.refs[refName].generatePdf()
      };
    }
  },

  mounted() {
    this.$root.$on("change-language", () => {
      window.location.reload()
    });
  },

  beforeDestroy() {
    this.$root.$off("change-language");
  }

};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <FlagButton :drop-left="true" />
      <button class="btn btn-sm px-3 font-size-24 header-item"
              type="button"
              @click="$store.dispatch('theme/switchTheme').then(() => $store.dispatch('theme/updateTheme'))">
        <i :class="$store.getters['theme/isThemeDark'] ? 'ri ri-sun-line' : 'ri ri-moon-line'" style="color: #ffffff;"/>
      </button>
    </div>

    <div class="container-fluid py-4" v-if="getContentType() !== 'HTML_TO_PDF'">
      <div class="row no-gutters">
        <div class="col-2"/>
        <div class="col-8">
          <div class="card">
            <div class="card-body">
              <template v-if="getContentType() === 'TEXT'">
                <div v-html="getContent()" />
              </template>

              <div class="app-content">
                <vue-pdf-embed :source="getPdfUrl()" />
              </div>
            </div>
          </div>
        </div>

        <div class="col-2"/>
      </div>
    </div>

    <template v-if="getContentType() === 'HTML_TO_PDF'">
      <vue-html2pdf
          :ref="setRef('html2Pdf')"
          :show-layout="false"
          :float-layout="true"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1100"
          :pdf-quality="2"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="800px"
          :manual-pagination="false"
      >
        <section slot="pdf-content">
          <div v-html="getContent()" />
        </section>
      </vue-html2pdf>
    </template>
  </div>
</template>